@font-face {
  font-family: floyd;
  src: url(../fonts/floyd.TTF);
}
.pf-font {
	font-family: floyd;
	color: black;
	font-size: 60px;
	font-weight: bold;
	text-shadow: 5px 5px 8px pink;
}
.pf-font-small {
	font-family: floyd;
	font-size: 1rem;
	font-weight: bold;
}
.shadow-text {
	text-shadow: 3px 2px 8px pink;
}
body {
	background-color: black;
}
.border {
	border: 1px solid pink;
}
.callout {
	margin-top: 20px;
	padding: 0rem;
}
#head {
	background-image: url(../img/bricks2wide.jpg);
	background-position: center;
	height: 250px;
	border: 1px solid pink;
}

#body-content {
	color: white;
}
#body-content .cell {
	/*height: 100%;*/
	/*border: 1px solid red;*/
}
#body-content ul li {
	list-style-type: none;
}
#body-content p>.band-name, .pink {
	color: pink;
}
#body-content img {
	width: 88px;
}

.footer {
	background-color: black;
	border: 1px solid pink;
}
.footer .cell {
	color: pink;
}
.footer-left, .footer-center, .footer-right {
	margin: 10px;
}